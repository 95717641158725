'use strict';

// Instance Methods
require('./at');
require('./camelize');
require('./capitalize');
require('./chars');
require('./codes');
require('./compact');
require('./dasherize');
require('./decodeBase64');
require('./encodeBase64');
require('./escapeHTML');
require('./escapeURL');
require('./first');
require('./forEach');
require('./format');
require('./from');
require('./includes');
require('./insert');
require('./isBlank');
require('./isEmpty');
require('./last');
require('./lines');
require('./pad');
require('./padLeft');
require('./padRight');
require('./parameterize');
require('./remove');
require('./removeAll');
require('./removeTags');
require('./replaceAll');
require('./reverse');
require('./shift');
require('./spacify');
require('./stripTags');
require('./titleize');
require('./to');
require('./toNumber');
require('./trimLeft');
require('./trimRight');
require('./truncate');
require('./truncateOnWord');
require('./underscore');
require('./unescapeHTML');
require('./unescapeURL');
require('./words');

module.exports = require('sugar-core');