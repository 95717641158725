'use strict';

// Static Methods
require('./addLocale');
require('./create');
require('./getAllLocaleCodes');
require('./getAllLocales');
require('./getLocale');
require('./removeLocale');
require('./setLocale');

// Instance Methods
require('../number/day');
require('../number/dayAfter');
require('../number/dayAgo');
require('../number/dayBefore');
require('../number/dayFromNow');
require('../number/days');
require('../number/daysAfter');
require('../number/daysAgo');
require('../number/daysBefore');
require('../number/daysFromNow');
require('../number/duration');
require('../number/hour');
require('../number/hourAfter');
require('../number/hourAgo');
require('../number/hourBefore');
require('../number/hourFromNow');
require('../number/hours');
require('../number/hoursAfter');
require('../number/hoursAgo');
require('../number/hoursBefore');
require('../number/hoursFromNow');
require('../number/millisecond');
require('../number/millisecondAfter');
require('../number/millisecondAgo');
require('../number/millisecondBefore');
require('../number/millisecondFromNow');
require('../number/milliseconds');
require('../number/millisecondsAfter');
require('../number/millisecondsAgo');
require('../number/millisecondsBefore');
require('../number/millisecondsFromNow');
require('../number/minute');
require('../number/minuteAfter');
require('../number/minuteAgo');
require('../number/minuteBefore');
require('../number/minuteFromNow');
require('../number/minutes');
require('../number/minutesAfter');
require('../number/minutesAgo');
require('../number/minutesBefore');
require('../number/minutesFromNow');
require('../number/month');
require('../number/monthAfter');
require('../number/monthAgo');
require('../number/monthBefore');
require('../number/monthFromNow');
require('../number/months');
require('../number/monthsAfter');
require('../number/monthsAgo');
require('../number/monthsBefore');
require('../number/monthsFromNow');
require('../number/second');
require('../number/secondAfter');
require('../number/secondAgo');
require('../number/secondBefore');
require('../number/secondFromNow');
require('../number/seconds');
require('../number/secondsAfter');
require('../number/secondsAgo');
require('../number/secondsBefore');
require('../number/secondsFromNow');
require('../number/week');
require('../number/weekAfter');
require('../number/weekAgo');
require('../number/weekBefore');
require('../number/weekFromNow');
require('../number/weeks');
require('../number/weeksAfter');
require('../number/weeksAgo');
require('../number/weeksBefore');
require('../number/weeksFromNow');
require('../number/year');
require('../number/yearAfter');
require('../number/yearAgo');
require('../number/yearBefore');
require('../number/yearFromNow');
require('../number/years');
require('../number/yearsAfter');
require('../number/yearsAgo');
require('../number/yearsBefore');
require('../number/yearsFromNow');
require('./addDays');
require('./addHours');
require('./addMilliseconds');
require('./addMinutes');
require('./addMonths');
require('./addSeconds');
require('./addWeeks');
require('./addYears');
require('./advance');
require('./beginningOfDay');
require('./beginningOfISOWeek');
require('./beginningOfMonth');
require('./beginningOfWeek');
require('./beginningOfYear');
require('./clone');
require('./daysAgo');
require('./daysFromNow');
require('./daysInMonth');
require('./daysSince');
require('./daysUntil');
require('./endOfDay');
require('./endOfISOWeek');
require('./endOfMonth');
require('./endOfWeek');
require('./endOfYear');
require('./format');
require('./get');
require('./getISOWeek');
require('./getUTCOffset');
require('./getUTCWeekday');
require('./getWeekday');
require('./hoursAgo');
require('./hoursFromNow');
require('./hoursSince');
require('./hoursUntil');
require('./is');
require('./isAfter');
require('./isBefore');
require('./isBetween');
require('./isFriday');
require('./isFuture');
require('./isLastMonth');
require('./isLastWeek');
require('./isLastYear');
require('./isLeapYear');
require('./isMonday');
require('./isNextMonth');
require('./isNextWeek');
require('./isNextYear');
require('./isPast');
require('./isSaturday');
require('./isSunday');
require('./isThisMonth');
require('./isThisWeek');
require('./isThisYear');
require('./isThursday');
require('./isToday');
require('./isTomorrow');
require('./isTuesday');
require('./isUTC');
require('./isValid');
require('./isWednesday');
require('./isWeekday');
require('./isWeekend');
require('./isYesterday');
require('./iso');
require('./millisecondsAgo');
require('./millisecondsFromNow');
require('./millisecondsSince');
require('./millisecondsUntil');
require('./minutesAgo');
require('./minutesFromNow');
require('./minutesSince');
require('./minutesUntil');
require('./monthsAgo');
require('./monthsFromNow');
require('./monthsSince');
require('./monthsUntil');
require('./relative');
require('./relativeTo');
require('./reset');
require('./rewind');
require('./secondsAgo');
require('./secondsFromNow');
require('./secondsSince');
require('./secondsUntil');
require('./set');
require('./setISOWeek');
require('./setUTC');
require('./setWeekday');
require('./weeksAgo');
require('./weeksFromNow');
require('./weeksSince');
require('./weeksUntil');
require('./yearsAgo');
require('./yearsFromNow');
require('./yearsSince');
require('./yearsUntil');

// Accessors
require('./getOption');
require('./setOption');

module.exports = require('sugar-core');